.event-details-page {
    margin-top: 100px;
    height: 200%;
    padding: 0 60px;
    overflow: scroll;
    z-index: 1;
}

.event-detail-title {
    color: #E6B17E;
}

.event-details-top-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    border: 1px solid #E6B17E;
    border-radius: 10px;
    padding: 20px;
}

.event-basic-details-edit {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.label-value {
    display: flex;
    align-items: center;
    gap: 20px;
}

.label-value > .label {
    color: #C56E33;
}

.label-value > .value {
    color: #E6B17E;
    margin: 0 0 0 10px;
    font-size: 24px;
}

.budgetInput {
    margin: 20px 0;
    display: flex;
    align-items: center;
    gap : 20px;
}

.Pencil-Icon {
    cursor: pointer!important ;
}

.subEvents-horizontal {
    display: flex;
    align-items: center;
    gap: 30px;
}

.subEvent-card {
    height: 260px;
    min-width: 200px;
    border: 1px solid #E6B17E; 
    border-radius: 10px; 
    position: relative;
}

.subEvent-card:hover {
    background-color: #e6b07e3e !important;
}

.card-selected {
    background-color: #e6b07e3e !important;
}

.card-header {
    width: 100%;
}

.card-header > p {
    font-size: 24px;
    color: #E6B17E;
    text-align: center;
}

.card-body {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    justify-content: space-between;
}

.body-item {
    height: 40px;
    width: 160px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.body-item > p {
    color: #E6B17E;
}
 
.switch-button-edit button {
    border-color: #E6B17E !important;
    color: #E6B17E !important;
    width: 150px;
}

.button-group {
    display: flex;
    gap: 10px;
}

.cards-ul {
    display: flex;
    overflow-x: scroll;
    max-width: 100%;
    gap: 20px;
    list-style: none;
    align-items: center;
    padding-left: 0;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        background: transparent; /* make scrollbar transparent */
        -webkit-appearance: none;
        width: 0;
        height: 0;
      }
}

.scroll-button {
    height : 40px;
    min-width: 40px !important;
    font-size: 40px;
    width: 40px;
    background-color: transparent !important;
    border-radius: 5px;
    border: 1px solid #E6B17E !important;
    color: #E6B17E !important;
}

.scroll-button:hover {
    color: #C56E33 !important;
    background-color: #E6B17E !important;
}

.Edit-Card-Icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.Edit-Card-Icon > svg {
    font-size: 20px !important;
}



