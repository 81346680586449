/* 
Light brown: #E6B17E
Dark brown: #C56E33 
*/

.App { 
  position: absolute;
  background-color: #000000;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  background-attachment: fixed;
}

.App::before {
  /* text-align: center; */ 
  content: "";
  position: absolute;
  background-image: url('../public/bg/pexels-mikhail-nilov-7676887.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;  
  background-attachment: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.1;
}
