.my-events {
    margin-top: 100px;
    height: 200%;
    padding: 0 60px;
    overflow: scroll;
    z-index: 1;
}

.myEvent-header {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.Edit-button {
    justify-self: right;
}

.myEvent-title {
    justify-self: start;
    font-family: 'Roboto Condensed', sans-serif;
    color: #E6B17E;
    font-size: 32px;
}

.event-title, .event-desc, .event-date, .total-budget {
    color: #E6B17E;
}

.all-events {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 30px 0;
    width: 100%;
}

.event-list-item {
    display: flex;
    padding: 10px 15px;
    flex-direction: column;
    border: 1px solid #E6B17E;
    border-radius: 10px;
    cursor: pointer;
    width: 80%;
    /* background-color: #e6b07e2d; */
}
.event-list-item:hover {
    background-color: #e6b07e3e !important;
}


.item-top-row, .item-bottom-row {
    display: flex;
    align-items: center;
    justify-content:space-between;
}

.event-title {
    font-size: 28px;
    margin: 0;
    /* border-bottom: 1px solid #E6B17E; */
    padding: 5px;
    width: 33.33%;
    /* border-radius: 5px; */
}

.date-time {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    width: 33.33%;
}

.total-budget {
    width: 33.33%;
    text-align: right;
}

.deleteButton {
    background-color: transparent !important;
    border: 1px solid #E6B17E !important;
    width: 130px !important;
}

p {
    font-family: 'Roboto Condensed', sans-serif;
}

.spinner-flex {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.delete-event-icon {
    z-index: 20;
}
