@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

.homePage {
    position: relative;
    display: flex;
    gap: 30px;
    align-items: center;
    padding: 100px 60px 0 60px;
    height: 100%;
    overflow: scroll;
}

.text-container {
    display: flex;
    gap: 20px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 12px; 
    padding: 20px 20px;
}

.text-container > p {
    color: #ffffff;
    font-family: 'Roboto Condensed', sans-serif;
    margin: 0;
}

.big-font {
    font-size: 2.2em;
}

.medium-font {
    font-size: 1.6em;
}

.create-button {
    color: #C56E33 !important;
    background-color: #E6B17E !important; 
    font-weight: bold !important;
    font-family: 'Roboto Condensed', sans-serif !important;

    width: 25%;
    align-self: center;
}